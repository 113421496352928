
















































import Vue from 'vue';
import { Milestone } from '@/scripts/store/modules/my_starling/types';

export default Vue.extend({
  name: 'profile-milestone',
  props: {
    milestone: {
      type: Object as () => Milestone,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { badgeUrl: null as null | string };
  },
  mounted() {
    if (this.milestone.badge) {
      this.badgeUrl = URL.createObjectURL(new Blob([ this.milestone.badge ], { type: 'image/svg+xml' }));
    }
  },
  beforeDestroy() {
    if (this.badgeUrl) {
      URL.revokeObjectURL(this.badgeUrl);
    }
  },
  methods: {
    onClick() {
      this.$emit('selected', this.milestone);
    },
  },
});
