var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _vm.horizontal
              ? [
                  _vm.milestone
                    ? _c(
                        "v-card",
                        {
                          staticClass: "radius-15 clickable",
                          class: {
                            "elevation-4": !hover,
                            "elevation-12": hover
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClick($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-2" },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs10: "", sm11: "" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            display: "block",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translate(0, -50%)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n             " +
                                              _vm._s(_vm.milestone.title) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-right",
                                      attrs: { xs2: "", sm1: "" }
                                    },
                                    [
                                      _vm.badgeUrl
                                        ? _c("v-img", {
                                            attrs: {
                                              contain: "",
                                              "aspect-ratio": "1",
                                              src: _vm.badgeUrl,
                                              alt: _vm.milestone.altText
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : [
                  _vm.milestone
                    ? _c(
                        "v-card",
                        {
                          staticClass: "milestone-card cursor-pointer",
                          class: {
                            "elevation-12": hover
                          },
                          attrs: { flat: !hover },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClick($event)
                            }
                          }
                        },
                        [
                          _vm.badgeUrl
                            ? _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        xs10: "",
                                        sm8: "",
                                        md6: "",
                                        lg5: ""
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          contain: "",
                                          "aspect-ratio": "1",
                                          src: _vm.badgeUrl,
                                          alt: _vm.milestone.altText
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-layout",
                            { staticClass: "mt-2", attrs: { row: "" } },
                            [
                              _c("v-flex", { staticClass: "text-center" }, [
                                _c("p", { staticClass: "starling-text" }, [
                                  _vm._v(_vm._s(_vm.milestone.title))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }